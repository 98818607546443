<template lang="pug">
transition(name="modal")
  .modal-mask(style="z-index: 100000")
    .modal-wrapper
      .modal-dialog(style="width: 460px;max-width: 95%;" role="document")
        confirmation-modal(
          @close="closeModal()" 
          @confirm="onLogout()"
          :title="isImpersonateSession ? 'Akhiri Sesi' : 'Keluar'"
          :confirm-text="isImpersonateSession ? 'Ya, Akhiri Sesi' : 'Ya, Keluar'"
        )
          p.m-0(v-if="isImpersonateSession")
            | You are going to end this user session and go back to superadmin session. Are you sure?
          p.m-0(v-if="!isImpersonateSession")
            | Apakah Anda yakin ingin keluar dari akun ini?
</template>

<script>
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import { mapGetters } from "vuex";
import { getCheckFeedback } from "@/utils/api";
export default {
  props: [],
  components: {
    ConfirmationModal,
  },
  computed: {
    isImpersonateSession() {
      return Boolean((this.$cookies.get("original_session") || {}).token);
    },
    ...mapGetters({
      auth: "gettersAuth",
    }),
  },
  data() {
    return {
      showDeleteModal: false,
    };
  },

  methods: {
    closeModal() {
      this.$store.commit(
        "setShowLogoutModal",
        !this.$store.state.showLogoutModal
      );
    },
    async checkFeedback() {
      try {
        let response = await getCheckFeedback({ user_id: this.auth.user_id });
        return response.data.result.status;
      } catch (error) {
        console.log(error, "logout modal");
      }
    },
    async onLogout(timeout = 1000) {
      this.$store.commit("setShowLogoutModal", false);
      let checkFeed = await this.checkFeedback();
      if (checkFeed) {
        this.$store.commit("setShowFeedbackModal", false);
      } else {
        this.$store.commit("setShowFeedbackModal", true);
      }
      this.$cookies.remove("token");
      this.$cookies.remove("user_id");
      this.$cookies.remove("role");
      try {
        if (this.$cookies.get("original_session")) {
          const originalSession = this.$cookies.get("original_session");
          this.$cookies.set("token", originalSession.token);
          this.$cookies.set("user_id", originalSession.user_id);
          this.$cookies.set("role", originalSession.role);
          this.$cookies.remove("original_session");
          setTimeout(() => window.location.replace("/"), timeout);
        } else {
          setTimeout(() => this.$router.push({ path: "/login" }), timeout);
        }
      } catch (error) {
        setTimeout(() => this.$router.push({ path: "/login" }), timeout);
      }
    },
  },
  mounted() {
    console.log(this.auth, "ini auth");
  },
};
</script>
