import { render, staticRenderFns } from "./SidebarModalCustomer.vue?vue&type=template&id=3f760988&lang=pug"
import script from "./SidebarModalCustomer.vue?vue&type=script&lang=js"
export * from "./SidebarModalCustomer.vue?vue&type=script&lang=js"
import style0 from "./SidebarModalCustomer.vue?vue&type=style&index=0&id=3f760988&prod&lang=scss&scope=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports