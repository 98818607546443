<template>
  <div class="content">
    <div class="container-fluid">
      <transition name="fade" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </transition>
      <div
        v-show="
          statusCart &&
          this.$route.name != 'Etalase Make Order' &&
          this.$route.name != 'Etalase Make Order Redirect Checkout' &&
          this.$route.name != 'invoice'
        "
      ></div>
      <rent-cart-modal
        v-if="cartModal"
        @close="cartModal = false"
      ></rent-cart-modal>
    </div>
  </div>
</template>
<script>
import RentCartModal from "../../components/Matador/RentCartModal.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      cartModal: false,
    };
  },
  components: {
    RentCartModal,
  },
  computed: {
    ...mapGetters({
      statusCart: "gettersCartStatus",
      auth: "gettersAuth",
    }),
  },
  methods: {
    showCartModal() {
      this.cartModal = true;
    },
  },
  async mounted() {
    this.$store.dispatch("getUserCart", this.auth.user_id);
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
  /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.btn-icon-cart {
  height: 80px;
  width: 80px;
  border-radius: 180px;
}
</style>
