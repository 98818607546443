<template lang="pug">
div
  loader(:loading="loading")
    div
      div(v-if="isPdf")
        h3.mb-4.font-weight-bold Location
        div(v-html="pdfTable") {{ pdfTable }}
      div(v-if="!isPdf")
        div.border-left.border-right.border-bottom.table-report.table-responsive
          b-table.m-0(
            ref="table"
            :busy.sync='isBusy'
            :filter='search'
            :items='tableData'
            :fields='fields'
            :perPage='perPage'
            :show-empty="true"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :filter-debounce="250"
            @filtered="onFiltered"
          )
            template(#cell(number)="data")
              span {{ data.index + 1 }}
            template(#cell(start_time)="data")
              span {{ new Date(data.item.start_time) }}
            template(#cell(end_time)="data")
              span {{ new Date(data.item.end_time) }}
            template(#cell(start_point)="data")
              span {{ data.item.start_point }}
            template(#cell(end_point)="data")
              span {{ data.item.end_point }}
            template(#cell(move_duration)="data")
              span {{ data.item.move_duration.days ? `${data.item.move_duration.days} Days` : ''}} {{ data.item.move_duration.hours ? `${data.item.move_duration.hours} Hours` : ''}} {{ data.item.move_duration.minutes ? `${data.item.move_duration.minutes} Minutes` : '' }}  {{ data.item.move_duration.seconds ? `${data.item.move_duration.seconds} Seconds` : '' }}
            template(#cell(average_speed)="data")
              span {{ data.item.average_speed }}
            template(#cell(overspeed_count)="data")
              span {{ data.item.overspeed_count }} x
            template(#cell(route_distance)="data")
              span {{ (data.item.route_distance / 1000).toFixed(4) }}
        .mt-3
          b-pagination(
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          )
</template>

<script>
import { getLogReportData } from '../../../utils/api'
import dayjs from 'dayjs'
export default {
  components: {

  },
  data(){
    return {
      loading: false,
      tableData: [],
      pdfTable: '<h1>Hello</h1>',
      isBusy: false,
      search: '',
      fields: [
        { key: 'number', label: 'No', sortable: false, class: '' },
        { key: 'start_time', label: 'Time Start', sortable: !this.isPdf, class: '' },
        { key: 'end_time', label: 'Time End', sortable: !this.isPdf, class: '' },
        { key: 'start_point', label: 'Route Start', sortable: !this.isPdf, class: '' },
        { key: 'end_point', label: 'Route End', sortable: !this.isPdf, class: '' },
        { key: 'move_duration', label: 'Move Duration', sortable: !this.isPdf, class: '' },
        { key: 'max_speed', label: 'Max Speed (km/h)', sortable: !this.isPdf, class: '' },
        { key: 'average_speed', label: 'Average Speed  (km/h)', sortable: !this.isPdf, class: '' },
        { key: 'overspeed_count', label: 'Overspeed Count', sortable: !this.isPdf, class: '' },
        { key: 'route_distance', label: 'Route Length (km)', sortable: !this.isPdf, class: '' },
      ],
      perPage: 10,
      currentPage: 1,
      totalRows: 0
    }
  },
  watch: {
    reportToken: {
      immediate: true,
      handler: function(newVal){
        this.getReport()
      },
      deep: true
    }
  },
  props: ['device_id', 'imei', 'dates', 'report-token', 'load', 'is-pdf'],
  methods: {
    onFiltered(){

    },
    getReport(){
      const {
        device_id,
        imei,
        dates: [
          start_date,
          end_date
        ]
      } = this;
      if(device_id && start_date){
        this.loading = true;
        getLogReportData({
          imei,
          start_date,
          end_date
        }).then(({ data: { result } }) => {
          this.loading = false;
          this.tableData = result.map((item, index) => {
            item.num = index+1;
            return item;
          });
          this.totalRows = result.length;
          this.$emit('load');
          if(this.isPdf) {
            this.perPage = result.length;
            this.populateNativeTable();
          }
        })
      }
    },
    populateNativeTable(){
      let table = `<table class="table table-bordered m-0">`;
      table += `<thead>`;
      table += `<tr>`;
      this.fields.forEach(item => {
        table += `<th>${item.label}</th>`;
      });
      table += `</tr>`;
      table += `</thead>`;
      table += `<tbody>`;
      this.tableData.forEach(row => {
        table += `<tr>`;
        this.fields.forEach(item => {
          table += item.key == 'timestamp' ? `<td>
            ${dayjs(row[item.key]).format('YYYY-MM-DD HH:mm:ss')}
          </td>` : `<td>${row[item.key]}</td>`;
        });
        table += `</tr>`;
      });
      table += `</tbody>`;
      table += `</table>`;
      this.pdfTable = table;
    }
  }
}
</script>
