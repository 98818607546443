<template lang="pug">
#geofence-form(:class="{'visible': visible}")
  div
    h4.h5.font-weight-bold.text-dark.mt-2.mb-4 Geofence Properties
    b-form-group(label="Name")
      b-form-input(v-model="name")
    b-form-group(label="Device Name")
      multiselect(
        v-model="selectedDevice",
        track-by="device_unit_id",
        :multiple="true",
        label="description",
        placeholder="Insert vehicle",
        :options="deviceList",
        :searchable="true",
        :allow-empty="false",
        :closeOnSelect="false"
      )
    b-form-group(label="Description")
      b-form-textarea(v-model="description" :rows="4")
    b-form-group
      .row
        .col-5
          label Color
          .row
            .col-4(v-for="item in colors")
              a.color-selector(href="javascript:;" @click="changeColor(item)")
                span.color(:style="'background-color: '+item")
                span.tick(v-if="color === item") ✔
        .col-auto.ml-auto
          label.d-block Auto-off (Engine)
          toggle-button(
            :sync="true"
            :margin="4"
            :width="40"
            :height="22" 
            :value="auto_off" 
            :color="{'checked': '#c0f5c0', 'unchecked': '#ffe0df'}"
            :labels="{'checked': 'On', 'unchecked': 'Off'}"
            @change="onSwitchAutoEngine"
          )
    .d-flex.justify-content-end
      button.btn.btn-light.py-1.px-4.border-0(@click="$emit('cancel')")
        span.d-block.px-3.text-danger Cancel
      button.btn.btn-danger.py-1.px-4(:disabled="!isCanSave" @click="onSave") 
        span.d-block.px-3 Save
</template>

<script>
import {
  getDeviceUnit,
  postAddGeofence,
  postUpdateGeofence
} from "../../../utils/api";

export default {
  data() {
    return {
      geofence_id: null,
      selectedDevice: [],
      deviceList: [],
      name: null,
      device_unit_id: [],
      description: null,
      auto_off: false
    };
  },
  props: ["visible", "colors", "color", "paths", "geofence"],
  computed: {
    coordinates() {
      return this.paths.map(({ lat, lng }) => [lat, lng]);
    },
    isCanSave() {
      return (
        this.name &&
        this.description &&
        this.device_unit_id.length > 0 &&
        this.coordinates.length >= 3 &&
        this.color
      );
    }
  },
  watch: {
    selectedDevice: function(newVal) {
      this.device_unit_id = newVal.map(item => item.device_unit_id);
    },
    visible: {
      immediate: true,
      handler: function(visible) {}
    },
    geofence: {
      immediate: true,
      handler: function(val) {
        if (!!val) {
          // console.log(val);
          this.name = val.name;
          this.description = val.description;
          this.auto_off = val.action === "on";
        }
      }
    }
  },
  methods: {
    onSwitchAutoEngine({ value: val }) {
      this.auto_off = val;
    },
    changeColor(color) {
      this.$emit("switchColor", color);
    },
    onSave() {
      let body = {
        name: this.name,
        coordinate: JSON.stringify(this.coordinates)
          .replace(/\[/g, "{")
          .replace(/\]/g, "}"),
        color: this.color,
        action: this.auto_off,
        description: this.description,
        device_unit_id: this.device_unit_id
      };
      if (!this.geofence) {
        body.organization_id = this.$store.state.auth.organization_id;
        body.created_by = this.$store.state.auth.user_id;
      } else {
        body.geofence_id = this.geofence.geofence_id;
      }
      if (!body.geofence_id) {
        postAddGeofence(body).then(response => {
          this.$emit("cancel");
          let closeButton = document.querySelector(".close-map");
          if (response.data.code != 200) {
            this.$toast.open(response.data.messgae);
          } else {
            this.$toast.open("Successfully added geofence");
            if (closeButton) {
              closeButton.click();
            }
          }
        });
      }
      if (body.geofence_id) {
        postUpdateGeofence(body).then(response => {
          this.$emit("cancel");
          let closeButton = document.querySelector(".close-map");
          if (response.data.code != 200) {
            this.$toast.open(response.data.messgae);
          } else {
            this.$toast.open("Successfully updated geofence");
            if (closeButton) {
              closeButton.click();
            }
          }
        });
      }
    }
  },
  mounted() {
    getDeviceUnit().then(({ data: { code, result, message } }) => {
      this.deviceList = result;
      if (this.geofence) {
        result.forEach(element => {
          if (
            this.geofence.device
              .map(({ device_unit_id }) => device_unit_id)
              .indexOf(element.device_unit_id) >= 0
          )
            this.selectedDevice.push(element);
        });
      }
    });
    // if(this.$store.state.devMode){
    //   this.name = "Test "+ Math.random();
    //   this.description = "Test description";
    // }
  }
};
</script>

<style lang="scss">
$form-width: 360px;
#geofence-form {
  position: absolute;
  overflow-y: auto;
  width: $form-width;
  height: 100%;
  background-color: #ffffff;
  top: 0;
  left: -$form-width - 5px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: 3px 0px 5px rgba(0, 0, 0, 0.15);
  padding: 20px 30px;
  transition: 500ms ease-in-out;
  &.visible {
    left: 0;
  }
  .color-selector {
    position: relative;
    span.color {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    span.tick {
      position: absolute;
      top: -8px;
      left: 4px;
      font-size: 2rem;
      color: #ffffff;
    }
  }
  .btn.border-0 {
    border-color: transparent !important;
  }
}
</style>
