<template lang="pug">
div(:class="{'flex-grow-1' : isFlexGrow}")
  component(:is='tag' :id='id' :type='nativeType' :disabled='disabled' @click="$emit('click')" class="form-control matador-search pr-5" :class="[\
  {[`form-control-${size}`]: size},\
  ]", :placeholder="placeholder", v-bind:value="value", v-on:input="$emit('input', $event.target.value)", autocomplete="autocomplete")
  magnify.p-viewer.icon-2x.matador-text-03
</template>
<script>
import Magnify from "vue-material-design-icons/Magnify.vue";
export default {
  name: "matador-search",
  components: {
    Magnify
  },
  props: {
    id: String,
    tag: {
      type: String,
      default: "input"
    },
    disabled: Boolean,
    click: Function,
    nativeType: {
      type: String,
      default: "search"
    },
    size: {
      type: String,
      default: "sm"
    },
    simple: Boolean,
    placeholder: {
      type: String,
      default: "Search"
    },
    autocomplete: {
      type: String,
      default: "off"
    },
    value: {
      type: String,
      default: ""
    },
    isFlexGrow: {
      type: Boolean,
      default: true
    }
  }
};
</script>
<style lang="scss">
@import "../../assets/sass/paper/_variables.scss";
.p-viewer {
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.matador-search {
  background: $matador-bg-02 !important;
  border-color: white !important;
  border-radius: 8px;
}
</style>
