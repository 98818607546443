<template lang="pug">
  b-sidebar( id="sidebar-detail" :visible="visible"  bg-variant="white" right no-header shadow  no-close)
    .d-flex.justify-content-between.ml-3.mt-4
      div(style="width:60%")
        .matador-h3.matador-text-01 Order Details
      a.px-3.py-2.close(
        href="javascript:;"
        role="button",
        data-dismiss="modal",
        aria-label="Close"
        @click="$emit('close')"
        )
        div
          img(:height="20" :width="20" src="@/assets/img/close.png")      
    .d-flex.ml-3
      .matador-text-03 Status
    .d-flex.ml-3
      .matador-h5.ready( v-if="dataOrder.status=='Order Created'||dataOrder.status=='Order Confirmed'||dataOrder.status=='Order Ready'||dataOrder.status== 'On Rent'") {{dataOrder.status}}
      .matador-h5.endsoon( v-if="dataOrder.status=='End Soon'") {{dataOrder.status}}
      .matador-h5.timeout( v-if="dataOrder.status== 'Time Up' || dataOrder.status=='Order Canceled' ") {{dataOrder.status}}
      .matador-h5.done( v-if="dataOrder.status=='Returned'||dataOrder.status=='Order Done'") {{dataOrder.status}}
    .d-flex.mt-3.ml-3(style="width:100%")
        .col-4(style="padding:0px 0px !important")
          .matador-text-03 Order ID       
        .col-8
          span.matador-h5.matador-text-03 : {{detailOrder.order_id}}
    .d-flex.ml-3(style="width:100%")
        .col-4(style="padding:0px 0px !important")
          .matador-text-03 Order Date      
        .col-8
          span.matador-h5.matador-text-03 : {{formatDateTime(detailOrder.order_date)}}
    .d-flex.ml-3(style="width:100%")
        .col-4(style="padding:0px 0px !important")
          .matador-text-03 Start Time    
        .col-8
          span.matador-h5.matador-text-03 : {{formatDateTime(detailOrder.start)}}
    .d-flex.ml-3(style="width:100%")
        .col-4(style="padding:0px 0px !important")
          .matador-text-03 Retun Time    
        .col-8
          span.matador-h5.matador-text-03 : {{formatDateTime(detailOrder.finish)}}
    //- .accordion
    //-   div
    //-       b-button.matador-bg-03(block='' @click="orderSummaryFunc()" style="text-align: left !important;border-radius: 15px;") 
    //-           .d-flex
    //-             div.w-100
    //-               .matador-h5.matador-text-03 Choose Rental Plan 
    //-             span.matador-text-03(v-show="showOrderSummary!=false" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
    //-             span.matador-text-03(v-show="showOrderSummary!=true" style="margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
    //-       b-collapse#accordion-1(:visible='showOrderSummary' accordion='my-accordion' role='tabpanel' appear)
    //-         .matador-h5 cndskncsjk
      
    .accordion.mt-3.ml-3.pr-3(role='tablist')
          b-button.matador-bg-03( @click="orderSummaryFunc()" style="width:100% !important;border-radius: 5px;") 
            .d-flex
              div.w-100(style="text-align: left !important;")
                .matador-h5.matador-text-03 Order Summary
              span.matador-text-03(v-show="showOrderSummary!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
              span.matador-text-03(v-show="showOrderSummary!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          div(v-if="detailOrder.status == 'Order Created'") 
            b-collapse#accordion-1(:visible='showOrderSummary' accordion='my-accordion' role='tabpanel' appear)
              div(v-for="(item,index) in detailOrder.unit" :key="index")
                .d-flex(style="width:100%").mt-2
                  .col-1(style="padding:0px 0px !important")
                    .matador-h5.matador-text-02 {{++index}}
                  .col-4
                    img.image-vehicle.mb-2.mb-lg-0(  src="@/assets/img/car-placeholder.png")
                  .col-5(style="padding:0px 0px !important")
                    .row 
                      .matador-h5.matador-text-02 {{item.series}}
                    .row.pt-1 
                      div
                        .matador-text-03 Qty (unit) : {{item.qty}}
                      div.ml-2(style="margin-top:-8px !important;width:40%")
                  .col-2(style="padding:0px 0px !important")
                    .row 
                    .row.pt-1(style="text-align: right !important;") 
                      .matador-text-03 Rp {{item.total_price}},-
                div.mt-2(v-show="index==detailOrder.unit.length" style="border-bottom: 1px solid #D3D4D8")
              .d-flex.mt-2.justify-content-between
                .matador-text-02 Total
                .matador-h5.matador-text-01 Rp {{total}},-
          div(v-else) 
            b-collapse#accordion-1(:visible='showOrderSummary' accordion='my-accordion' role='tabpanel' appear)
              div(v-for="(item,index) in detailOrder.unit" :key="index")
                .d-flex(style="width:100%").mt-2
                  .col-1(style="padding:0px 0px !important")
                    .matador-h5.matador-text-02 {{++index}}
                  .col-4
                    img.image-vehicle.mb-2.mb-lg-0(  src="@/assets/img/car-placeholder.png")
                  .col-4(style="padding:0px 0px !important")
                    .row 
                      .matador-h5.matador-text-02 {{item.series}}
                    .row.pt-1 
                      div
                        .matador-text-03 Qty (unit) : {{item.qty}}
                      div.ml-2(style="margin-top:-8px !important;width:40%")
                .d-flex
                  .col-1
                  .col-8 
                    .d-flex
                      .matador-text-03 VIN 
                        span.ml-4.mr-4 :
                      .matador-text-03(v-for="(items,indexs) in item.vin" :key="indexs")  {{items==null?'-': ' ' + items+" ,"}}
                .d-flex
                  .col-1
                  .col-8
                    .d-flex
                      .matador-text-03 Amount
                        span.ml-4.mr-4 :
                      .matador-text-01 Rp {{item.total_price}},-
                div(v-show="index==detailOrder.unit.length" style="border-bottom: 1px solid #D3D4D8")
              .d-flex.mt-2.justify-content-between
                .matador-text-02 Total Amount
                .matador-h5.matador-text-01 Rp {{total}},-
                  
    .accordion.mt-4.ml-3.pr-3(role='tablist')
          b-button.matador-bg-03( @click="orderCustomerFunc()" style="width:100% !important;border-radius: 5px;") 
            .d-flex
              div.w-100(style="text-align: left !important;")
                .matador-h5.matador-text-03 Customer
              span.matador-text-03(v-show="showCustomer!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
              span.matador-text-03(v-show="showCustomer!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-2(:visible='showCustomer' accordion='my-accordion#2' role='tabpanel' appear)
            .d-flex
              .col-3
                img.image-profile.mt-2.mb-lg-0(  src="@/assets/img/car-placeholder.png")
              .col-3.pt-2
                .matador-h5.matador-text-02 {{detailOrder.customer}}
                .matador-text-02 {{detailOrder.email}}
                .matador-text-02 {{detailOrder.phone==null ?"-":detailOrder.phone}}

    //- .accordion.mt-4.ml-3.pr-3(role='tablist' v-show="detailOrder.status != 'Order Created'" )
    .accordion.mt-4.ml-3.pr-3(role='tablist' v-if="detailOrder.status != 'Order Created' && detailOrder.status != 'Order Canceled'" )
          b-button.matador-bg-03( @click="orderPickUpFunc()" style="width:100% !important;border-radius: 5px;") 
            .d-flex
              div.w-100(style="text-align: left !important;")
                .matador-h5.matador-text-03 Pick Up PIC Information
              span.matador-text-03(v-show="showPickUp!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
              span.matador-text-03(v-show="showPickUp!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-4(:visible='showPickUp' accordion='my-accordion#4' role='tabpanel' appear)
            .d-flex
              .col-3
                .matador-text-03 Name 
              .col-5
                .matador-h5.matador-text-02 : {{detailOrder.additional_data.pickup_by.name}}
            .d-flex
              .col-3
                .matador-text-03 Phone No.
              .col-5
                .matador-h5.matador-text-02 : {{detailOrder.additional_data.pickup_by.phone}}

    .accordion.mt-4.ml-3.pr-3(role='tablist' v-if="detailOrder.status == 'Order Done'" )
          b-button.matador-bg-03( @click="orderReturnFunc()" style="width:100% !important;border-radius: 5px;") 
            .d-flex
              div.w-100(style="text-align: left !important;")
                .matador-h5.matador-text-03 Return PIC Information
              span.matador-text-03(v-show="showReturn!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
              span.matador-text-03(v-show="showReturn!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-4(:visible='showReturn' accordion='my-accordion#4' role='tabpanel' appear)
            .d-flex
              .col-3
                .matador-text-03 Name 
              .col-5
                .matador-h5.matador-text-02 : {{detailOrder.additional_data.return_by.name}}
            .d-flex
              .col-3
                .matador-text-03 Phone No.
              .col-5
                .matador-h5.matador-text-02 : {{detailOrder.additional_data.return_by.phone}}
            .d-flex
              .col-3
                .matador-text-03 Note
              .col-5
                .matador-h5.matador-text-02 : {{detailOrder.additional_data.return_by.note}}
                
    .accordion.mt-3.ml-3.pr-3.mb-4(role='tablist')
          b-button.matador-bg-03( @click="orderTimelineFunc()" style="width:100% !important;border-radius: 5px;") 
            .d-flex
              div.w-100(style="text-align: left !important;")
                .matador-h5.matador-text-03 Timeline
              span.matador-text-03(v-show="showTimeline!=false" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-up-drop-circle-outline
              span.matador-text-03(v-show="showTimeline!=true" style="color:#8B90A0;margin-top:-3px;").mdi.mdi-18px.mdi-arrow-down-drop-circle-outline
          b-collapse#accordion-3(:visible='showTimeline' accordion='my-accordion#3' role='tabpanel' appear)
            div.px-3(v-for="item in detailOrder.timeline_status") 
              .d-flex(style="width:100%").mt-2
                .col-4
                  a {{item.created_at.slice(0,10)}}
                  br
                  a {{item.created_at.slice(11,19)}}
                .col-2
                  span.border-dot
                  span.straight-line 
                .col-5
                  span.text-order {{item.status}}  
                      //- b-form-input( type="number" v-model="item.qty" min="0" :max="item.qty")
</template>
<script>

import {
getDetailOrder,
postConfirmOrder,
postReturn
} from "@/utils/api";
import dayjs from 'dayjs';
export default {
  data(){
    return{
      showReturn:true,
      showPickUp:true,
      showTimeline:true,
      showCustomer:true,
      showOrderSummary:true,
      date : null,
      detailOrder : null,
      vin : null,
      optionVin: null,
      total : 0,
      selectedVin : [],
      vinn:['satu','dua','tiga']
    }
  },
watch:{      
 visible: {
      handler: function(newVal){
        }
        },
 dataOrder: {
      handler: function(newVal){
        if(newVal){
          console.log(newVal,"newVal")
          newVal.order_date =  newVal.order_date.replace('T',' ').split('.',1).toString()
          this.fetchDetailOrder(newVal.order_id)
          }
        }
        },
                  
},

  props: ['close', 'visible','dataOrder', 'success'],  
  methods:{
    orderSummaryFunc(){
      if(this.showOrderSummary){
        this.showOrderSummary=false
      }else{
        this.showOrderSummary=true
      }
    },
    orderReturnFunc(){
      if(this.showReturn){
        this.showReturn=false
      }else{
        this.showReturn=true
      }
    },
    orderPickUpFunc(){
      if(this.showPickUp){
        this.showPickUp=false
      }else{
        this.showPickUp=true
      }
    },
    orderTimelineFunc(){
      if(this.showTimeline){
        this.showTimeline=false
      }else{
        this.showTimeline=true
      }
    },
    orderCustomerFunc(){
      if(this.showCustomer){
        this.showCustomer=false
      }else{
        this.showCustomer=true
      }
    },
    formatDate(params){
      var date=dayjs(params).format('DD/MM/YYYY')
      return date
    },
    formatDateTime(params){
      var date=dayjs(params).format('DD/MM/YYYY HH:mm')
      return date
    },
    splitDate(value){
      return value.replace('T',' ')
    },
    fetchDetailOrder(params){
        getDetailOrder({order_id:params}).then(({ data: { code, result, message } }) => {
          // console.log(result)
          this.total=0
          result.unit.forEach((value, index) => {
            this.total+= value.total_price
          });
          // result.start = result.start.replace('T',' ').slice(0,19)
          // result.order_date = result.order_date.replace('T',' ').slice(0,19)
          // result.finish = result.finish.replace('T',' ').slice(0,19)
          result.timeline_status = result.timeline_status.reverse()
          this.detailOrder = result
         
      });
    },
    mataUang(req){
      if(req){
      var	number_string = req.toString()
      var sisa 	= number_string.length % 3
      var rupiah 	= number_string.substr(0, sisa)
      var ribuan 	= number_string.substr(sisa).match(/\d{3}/g)
          
      if (ribuan) {
        var separator = sisa ? '.' : '';
        rupiah += separator + ribuan.join('.');
      }
    
      return 'Rp. '+rupiah;
      }else{
        return req
      }
    } ,
    separateDate(date){
      
    
  }   
  },

  mounted(){
  }
}
</script>
<style lang="scss" scope>
@import "../../../assets/sass/paper/variables.scss";
.text-title-3
{
  color: #505565;
  /* Body 3 Semi Bold */
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
}
#sidebar-detail.b-sidebar{
  width: 440px !important;

}
    .ready {
      color:#0CCA4A !important}
    .endsoon{
      color:#FC9905
    }
    .timeout{
      color:#992024
    }
    .done{
      color:#005BFF
    }
  
.image-vehicle{
  max-width: 72px;
  max-height: 48px;
  left: 0px;
  top: 0px;

}
.image-profile{
  max-width: 72px;
  max-height: 72px;
  left: 0px;
  top: 0px;
  border-radius: 40px;

}
.btn-confirm
{
  border: #0CCA4A !important;
  background : #0CCA4A !important;
  border-radius: 5px !important;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  &.assign{
      width: 136px;
      height: 36px;
  }
  &.pickup{
      width: 136px;
      height: 36px;
  }
  &.return{
      width: 223px;
      height: 36px; 
  }
}
.date-line{

}
.time-line{

}
.green-dot {
  width: 20px;
  height: 20px;
  // left: 99px;
  // top: 10px;
  
  background-color: #0CCA4A;
  border-radius: 50%;
  display: inline-block;
}
.border-dot {
  width: 20px;
  height: 20px;
  border: 2px solid #0CCA4A;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
}
.staight-line {
position: absolute;
  width: 1px;
  height: 51px;

  border: 2px solid #0CCA4A;
}
.text-order{
color: #232735;
width: 97px;
height: 24px;
left: 129px;
top: 0px;

/* Body 3 Semi Bold */
font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 24px;
}
</style>