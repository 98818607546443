<template>
  <component
    :is="tag"
    @click.native="hideSidebar"
    class="nav-item"
    v-bind="$attrs"
    tag="li"
  >
    <a v-if="name !== 'Rental' && name !== 'Satellite'" class="nav-link">
      <slot>
        <i v-if="icon" :class="icon"></i>
        <p>{{ name }}</p>
        <span v-if="counter" class="sidebar-counter text-white">{{
          counter
        }}</span>
      </slot>
    </a>
    <a v-else-if="name === 'Rental'">
      <slot>
        <sidebar-dropdown></sidebar-dropdown>
      </slot>
    </a>
    <a v-else-if="name === 'Satellite'">
      <slot>
        <sidebar-dropdown2></sidebar-dropdown2>
      </slot>
    </a>
  </component>
</template>
<script>
import SidebarDropdown from "./SidebarDropdown.vue";
import SidebarDropdown2 from "./SidebarDropdown2.vue";
export default {
  name: "sidebar-link",
  inheritAttrs: false,
  components: {
    SidebarDropdown,
    SidebarDropdown2
  },
  inject: {
    autoClose: {
      default: screen.width < 760
    },
    addLink: {
      default: () => {}
    },
    removeLink: {
      default: () => {}
    }
  },
  props: {
    name: String,
    icon: String,
    counter: Number,
    tag: {
      type: String,
      default: "router-link"
    }
  },
  methods: {
    hideSidebar() {
      if (this.autoClose && screen.width < 760) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style></style>
