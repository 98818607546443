import produce from "immer";
import { Line, mixins } from "vue-chartjs";
import { minifyChartAmount } from "../../utils/utilities";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chart-data", "options", "gradients"],
  data() {
    return {
      gradient: null,
      gradientOne: null,
      gradientTwo: null,
    };
  },
  methods: {
    update() {
      this.$data._chart.update();
    },
    create() {
      const chartData = this.chartData;

      if (this.gradients) {
        this.gradientOne = this.gradients[0];
        this.gradientTwo = this.gradients[1];
        this.gradient = this.$refs.canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 450);
        this.gradient.addColorStop(0, this.gradients[0]);
        this.gradient.addColorStop(1, this.gradients[1]);
        chartData.datasets = chartData.datasets.map((item) => {
          item.backgroundColor = this.gradient;
          return item;
        });
      }

      this.renderChart(
        chartData,
        produce(this.options || {}, (draft) => {
          draft.scales = {
            yAxes: [
              {
                id: 'y-axis-0',
                position: 'left',
                ticks: {
                  beginAtZero: false,
                  callback: function (value) {
                    return minifyChartAmount(value) + ' L';
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Bahan Bakar (Liter)",
                  fontColor: "#333",
                  fontSize: 14,
                  fontStyle: "bold",
                },
              },
              {
                id: 'y-axis-1',
                position: 'right',
                ticks: {
                  beginAtZero: false,
                  callback: function (value) {
                    return value + ' km/h';
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Kecepatan (km/h)",
                  fontColor: "#333",
                  fontSize: 14,
                  fontStyle: "bold",
                },
                gridLines: {
                  drawOnChartArea: false, // agar grid tidak tumpang tindih
                },
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Jam",
                  fontColor: "#333",
                  fontSize: 14,
                  fontStyle: "bold",
                },
              },
            ],
          };
        })
      );
    },
  },
  mounted() {
    if (this.chartData) {
      const chartData = this.chartData;

      if (this.gradients) {
        this.gradientOne = this.gradients[0];
        this.gradientTwo = this.gradients[1];
        this.gradient = this.$refs.canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 450);
        this.gradient.addColorStop(0, this.gradients[0]);
        this.gradient.addColorStop(1, this.gradients[1]);
        chartData.datasets = chartData.datasets.map((item) => {
          item.backgroundColor = this.gradient;
          return item;
        });
      }

      this.renderChart(
        chartData,
        produce(this.options || {}, (draft) => {
          draft.scales = {
            yAxes: [
              {
                id: 'y-axis-0',
                position: 'left',
                ticks: {
                  beginAtZero: false,
                  mirror: false,
                  callback: function (value) {
                    if (value >= 1000000000) {
                      return (value / 1000000000) + " B";
                    } else if (value >= 1000000) {
                      return (value / 1000000) + " M";
                    } else if (value >= 1000) {
                      return (value / 1000) + " K";
                    }
                    return value + " L";
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Liter", // Menambahkan label pada sumbu Y
                  fontColor: "#333",  // Warna teks
                  fontSize: 14,       // Ukuran font label
                  fontStyle: "bold",  // Gaya teks
                },
              },
              {
                id: 'y-axis-1',
                position: 'right',
                ticks: {
                  beginAtZero: false,
                  mirror: false,
                  callback: function (value) {
                    if (value >= 1000000000) {
                      return (value / 1000000000).toFixed(2) + " B";
                    } else if (value >= 1000000) {
                      return (value / 1000000).toFixed(2) + " M";
                    } else if (value >= 1000) {
                      return (value / 1000).toFixed(2) + " K";
                    }
                    return value.toFixed(2) + " L";
                  },
                },
                scaleLabel: {
                  display: true,
                  labelString: "Meter", // Menambahkan label pada sumbu Y
                  fontColor: "#333",  // Warna teks
                  fontSize: 14,       // Ukuran font label
                  fontStyle: "bold",  // Gaya teks
                },
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: "Jam", // Menambahkan label pada sumbu X
                  fontColor: "#333",  // Warna teks
                  fontSize: 14,       // Ukuran font label
                  fontStyle: "bold",  // Gaya teks
                },
              },
            ],
          };
        })
      );
    }
  },
};