<template lang="pug">
  div.heading-v1
    h4.m-0.h5.font-weight-bold(v-if="size === 'sm'") {{ text }}
    div.divider
      div
</template>

<script>
export default {
  data(){
    return {
      
    }
  },
  props: ['text', 'size', 'suffix'],
  computed: {
    
  }
}
</script>

<style lang="scss">
.heading-v1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .h5 {
    font-size: 1rem;
    color: #444;
  }
  .divider {
    flex: 1;
    div {
      height: 1px;
      background-color: #d0d0d0;
      margin-left: 12px;
      margin-top: 2px;
    }
  }
}
</style>