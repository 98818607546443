/*!

 =========================================================
 * Vue Paper Dashboard - v2.0.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/paper-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import App from "./App";
import router from "./router/index";
import PaperDashboard from "./plugins/paperDashboard";
import store from "@/utils/store";
import VueCookies from "vue-cookies";
import ToggleButton from "vue-js-toggle-button";
import Fragment from "vue-fragment";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Bugsnag from "@/plugins/bugsnag";
import "@/components/Matador/Loader";
import "@/components/Matador/Checkbox";
import "@/components/Matador/EditIcon";
import "@/components/Matador/DeleteIcon";
import "es6-promise/auto";
import "vue-notifyjs/themes/default.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-material-design-icons/styles.css";
import "cropperjs/dist/cropper.min.css";
import GAuth from 'vue-google-oauth2'
import CKEditor from '@ckeditor/ckeditor5-vue2';
// import "@/assets/js/snap.sandbox.js"
const bugsnagVue = Bugsnag.getPlugin('vue');
bugsnagVue.installVueErrorHandler(Vue);

Vue.use( CKEditor );
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Fragment.Plugin);
Vue.use(ToggleButton);
Vue.use(PaperDashboard);
Vue.use(VueCookies);
const gauthOption = {
  clientId: '429778011673-g9qk73sscr8an7nvpi2n9edeid3t9ijn.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile:true
}
Vue.use(GAuth, gauthOption)
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

